@import "https://fonts.googleapis.com/css2?family=Fredericka+the+Great&family=Oswald:wght@200;400;500;600;700;800;900&display=swap";
*, :before, :after {
  box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
  font: inherit;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  text-size-adjust: none;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

footer, header, nav, section, main {
  display: block;
}

body {
  line-height: 1.2;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  appearance: none;
  border-radius: 0;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.active {
  background: #999;
}

input[type="text"] {
  border: 0;
  border-bottom: 1px solid;
  font-size: 1.1em;
  font-weight: bold;
}

input[type="text"]:focus {
  outline: none;
}

.icon svg {
  height: 1.2em;
  width: 1.2em;
}

button {
  color: #fff;
  cursor: pointer;
  background: #666;
  border: 0;
  border-radius: 0;
  outline: none;
  padding: .5em;
  font-size: .9em;
  display: inline-flex;
}

button:hover {
  background: #999;
}

button[disabled] {
  cursor: auto;
  opacity: .6;
}

button[disabled]:hover {
  background: #666;
}

.form-field {
  align-items: center;
  margin: 1em 0;
  display: flex;
}

.form-field label {
  margin-right: 20px;
}

.labelled-icon-button {
  flex: auto;
}

.labelled-icon-button .icon svg * {
  fill: #fff;
}

.labelled-icon-button .icon {
  border-right: 1px solid;
  margin-right: .4em;
  padding-right: .4em;
}

.toggles {
  align-items: center;
  display: flex;
}

.toggles button {
  flex: 1;
  justify-content: center;
  padding: 6px;
}

.button-row {
  margin: 1em 0;
  display: flex;
}

.button-row button {
  margin-left: .2em;
}

.button-row button:first-child {
  margin-left: 0;
}

#timer button, #mobbers button, #action {
  justify-content: center;
}

.tab {
  background: #fffc;
  padding: 1em 0;
}

.tab #share-link {
  color: #000;
  text-align: left;
  width: 100%;
  background: none;
  align-items: center;
  padding: 0;
  font-size: 1em;
  display: flex;
}

.tab #share-link .icon svg {
  height: 1.4em;
  width: 1.4em;
}

.tab #share-link .icon svg * {
  fill: #000;
}

.tab #share-link span {
  flex: 1;
}

.tab #share-link i {
  flex: 0 0;
}

.tab h3 {
  border-bottom: 1px solid;
  margin-top: 2em;
  padding-bottom: .5em;
  font-size: 1.1em;
  font-weight: bold;
}

.tab h3 .icon {
  vertical-align: middle;
  padding-right: .5em;
}

.tab h3:first-child {
  margin-top: 0;
}

#home.tab .mobber-roles {
  border-top: 1px solid #666;
  margin: 1em 0;
  padding-top: 1em;
}

#home.tab .mobber-roles .mobber-role {
  align-items: center;
  display: flex;
}

#home.tab .mobber-roles .mobber-role .role {
  flex: 1;
}

#home.tab .mobber-roles .mobber-role .mobber {
  flex: 2 2;
  font-size: 1.2em;
  font-weight: bold;
}

#connectionLost {
  height: 8px;
  width: 8px;
  background: red;
  border-radius: 100%;
  animation: 2s infinite blink;
  position: absolute;
  top: 10px;
  right: 10px;
  box-shadow: 1px 1px 4px red;
}

header section {
  margin: 0 auto;
  padding: 30px 0 120px;
  position: relative;
}

header section:before {
  content: "";
  opacity: .1;
  z-index: -1;
  background: url("k7.755f5cd9.jpg") top / cover no-repeat;
  position: absolute;
  inset: 0;
}

header section #circles {
  background: #ffffffb3;
  border-radius: 100%;
  margin: auto;
  display: block;
  transform: rotateZ(-90deg);
}

header section #circles circle {
  transition: stroke-dashoffset 1s linear;
}

#action .icon {
  margin: auto;
  display: block;
}

#action #time-left {
  display: none;
}

#action #time-left span {
  display: block;
}

#action.on .icon {
  display: none;
}

#action.on #time-left {
  display: block;
}

#action.on:hover #time-left {
  display: none;
}

#action.on:hover .icon {
  display: block;
}

#share-link {
  cursor: pointer;
}

#share-link strong {
  font-size: 1.2em;
  font-weight: bold;
}

#share-link i {
  font-size: 1.1em;
}

h2 #share-link {
  border-bottom: 1px solid;
  border-radius: 20px 20px 0 0;
  margin-top: 20px;
  padding: 5px 20px;
  display: inline-block;
  position: relative;
}

h2 #share-link i {
  vertical-align: middle;
  margin-left: 10px;
}

nav {
  background: #666;
  display: flex;
}

nav button {
  color: #fff;
  text-align: center;
  text-shadow: 1px 1px 1px #666;
  border-right: 1px solid #fafafa99;
  flex: 1;
  padding: .5em 0;
  display: inline-block;
}

nav button .icon svg {
  height: 2.1em;
  width: 2.1em;
}

nav button .icon svg * {
  fill: #fff;
}

nav button:first-child {
  border-left: 1px solid #fafafa99;
}

#login .form-field label {
  flex: 1;
}

#login .form-field input {
  flex: 6%;
}

#modal-container {
  background: #64646466;
  position: fixed;
  inset: 0;
}

#modal {
  background: #fff;
  margin: auto;
  padding: .5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#modal h2 {
  font-size: 1.3em;
}

#modal button {
  width: 100%;
  margin-top: 1em;
}

#toasts {
  margin: 0 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#toasts .toast {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  max-width: 400px;
  width: 100%;
  border-radius: 5px;
  align-items: center;
  margin: 10px 0 10px auto;
  padding: 15px;
  display: flex;
  position: relative;
  box-shadow: 0 0 10px #0009;
}

#toasts .toast .icon svg {
  height: 1.4em;
  width: 1.4em;
}

#toasts .toast .icon svg * {
  fill: #fff;
}

#toasts .toast.success {
  background: #35c135;
}

#toasts .toast.info {
  background: #00a9eb;
}

#toasts .toast.error {
  background: #eb0000;
}

#toasts .toast .level-icon {
  font-size: 1.5em;
}

#toasts .toast .content {
  padding: 0 15px;
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  color: #fff;
  white-space: normal;
  background-color: #333;
  border-radius: 4px;
  outline: 0;
  font-size: 14px;
  line-height: 1.4;
  transition-property: transform, visibility, opacity;
  position: relative;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: top;
  bottom: -7px;
  left: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -7px;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  transform-origin: 0;
  right: -7px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -7px;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333;
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tippy-content {
  z-index: 1;
  padding: 5px 9px;
  position: relative;
}

/*# sourceMappingURL=index.45f49308.css.map */
