// -------------------------------------------
// LOGIN
// -------------------------------------------

@use '../theme' as *;

#login {
    .form-field {
        label {
            flex: 1 1;
        }

        input {
            flex: 1 1 6%;
        }
    }
}
